// :cow: Cosmose CONFIDENTIAL :iso:
import { Action, createReducer, on } from '@ngrx/store';
import { getAdvertiserListFailed, getAdvertiserListRequested, getAdvertiserListSucceeded } from './select-advertiser.actions';
import { AdvertiserListResponse } from '@par/app/core/model/dictionaries.response';

export const STATE_NAME_SELECT_ADVERTISER = 'select-advertiser';

export interface State {
  response?: AdvertiserListResponse;
  responseError: boolean;
  responseLoading: boolean;
}

const initialState: State = {
  response: undefined,
  responseError: false,
  responseLoading: false,
};

const reducer = createReducer(
  initialState,
  on(getAdvertiserListRequested, (state) => ({
    ...state,
    response: undefined,
    responseLoading: true,
    responseError: false,
  })),
  on(getAdvertiserListSucceeded, (state, {response}) => ({
    ...state,
    responseLoading: false,
    response,
    responseError: false,
  })),
  on(getAdvertiserListFailed, (state) => ({...state, responseLoading: false, responseError: true})),
);

export function selectAdvertiserReducer(state: State | undefined, action: Action): any {
  return reducer(state, action);
}

